<template>
  <div class="login cb all" v-if="loading">
    <v-progress-circular
        :size="50"
        color="black"
        indeterminate
      ></v-progress-circular>
  </div>

  <div class="login" v-bind:style="{ backgroundImage: `url(${background})` }" v-else>
    <v-row style="height: 200px"></v-row>

    <v-row justify="center" class="pt-5">
      <v-col md="1" sm="1" />
      <v-col cols="12" md="9" sm="9" class="cb all avatar-logo">
        <v-avatar
          size="128"
          color="white"
          style="padding:2px;"
        >
          <v-img
            contain
            :lazy-src=logo
            :src=logo
            @error="logo=`${url_server}/files/logo_file/default_logo.png`"
          />
        </v-avatar>
      </v-col>
      <v-col md="1" sm="1" />

    </v-row>

    <v-row justify="center" class="pt-5">
      <v-card elevation="2" width="400">
        <v-container>
          <v-form 
            ref="form"
            lazy-validation
            @submit.prevent="validate"
            id="check-login-form"
          >
            <v-row justify="center" class="pt-5">
              <v-col md="1" sm="1" />

              <v-col cols="12" md="9" sm="9">
                <v-text-field
                  height="40"
                  clearable
                  color="black"
                  outlined
                  single-line
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>

              <v-col md="1" sm="1" />
            </v-row>

            <v-row justify="center">
              <v-col md="1" sm="1" />

              <v-col cols="12" md="9" sm="9">
                <v-text-field
                  clearable
                  color="black"
                  outlined
                  single-line
                  v-model="password"
                  :rules="[v => !!v || 'Password is required']"
                  type="password"
                  label="Password"
                  required
                ></v-text-field>
              </v-col>
              
              <v-col md="1" sm="1" />

            </v-row>

            <div class=" row cb all mb-3">
              <v-col md="1" sm="1" />
              <v-col cols="12" md="9" sm="9">
                <v-btn
                  :disabled="!valid"
                  depressed
                  elevation="2"
                  block
                  class="btn-border"
                  type="submit"
                  form="check-login-form"
                >
                  Login
                </v-btn>
              </v-col>
              <v-col md="1" sm="1" />

            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn depressed class="btn-border" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Auth from '@/api/AuthApi'
import global from '@/global.js'

  export default {
    data () {
      return {
        url_server: global.url_server,
        valid: true,
        password: '',
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],

        loading: true,

        snackbar: false,
        snackbar_text: null,
        

        logo: null,
        background: {}
      }
    },
    created() {
      this.getLogo();
      if (this.$cookie.get(`token`)) {
        this.$router.push({ name: `Dashboard` });
        location.reload()
      }
    },
    methods: {
      validate : async function () {
        this.$refs.form.validate()
        let res = await Auth.login({ email: this.email, password: this.password});
        if (res.data.status == 200) {
          this.snackbar = true;
          this.snackbar_text = res.data.message;
          if (res.data.data.token) {
            let expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            let data_user = await this.$jwtDec.decode(res.data.data.token);
            this.$cookie.set(`token`, res.data.data.token, { expires });
            this.$cookie.set(`data_user`, JSON.stringify(data_user), { expires });

            location.reload();
            setTimeout(() => {
              this.$router.push({ name: `Dashboard` });
            }, 1000)
          }
        } else {
          this.snackbar = true;
          this.snackbar_text = res.data.message;
        }
      },

      getLogo : async function () {
        let res = await Auth.getLogo();
        if (res.data.status == 200) {
          let data = res.data.data;
          this.logo = data.logo_files ? `${this.url_server}${data.logo_files}` : `${this.url_server}/files/logo_file/default_logo.svg`;
          this.background = data.background_files ? `${this.url_server}${data.background_files}` : `${this.url_server}/files/background_file/default_background.png`;
          this.loading = false
        } else {
          this.loading = false
          this.snackbar = true;
          this.snackbar_text = res.data.message;
        }
      }
    },
    
  }
</script>
<style>
  .login {
    height: 100%;
  }
  .v-text-field input {
    font-size: 12px;
  }
  .v-label {
    font-size: 12px;
  }
  .avatar-logo {
    margin-bottom: -45px;
    z-index: 1; 
  }
</style>
